<template>
  <v-container fluid>
    <v-row class="text-center box-container">
       <v-row class="text-center mt-2 ml-2 mr-2 " >
           <!--items-->
         <v-col cols="5" class="color-1 box-info text-left">
          <v-col class="up-text">
             <h2>Nombre Paciente</h2>
             </v-col>
           <v-col class="text-pers display-2">
               <i class="fas fa-user"></i> {{ nombre }}
           </v-col>

           </v-col>
           <v-col cols="5" class="color-2 text-left box-info">
             <v-col class="up-text">
             <h2>Nombre Médico</h2>
             </v-col>
           <v-col class="text-pers display-2">
                <i class="fas fa-user-md"></i> {{ medico }}
           </v-col>
           </v-col>
           <v-col cols="2" class="color-3 box-info text-left">
            
           <v-col class="text-pers display-2 mt-3">
                <i class="fas fa-paste"></i> BOX
           </v-col>
           {{box}}
           </v-col>
            <!--fin items-->
             <!--items-->
         <v-col cols="5" class="color-1 box-info text-left">
          <v-col class="up-text">
             <h2>Nombre Paciente</h2>
             </v-col>
           <v-col class="text-pers display-2">
               <i class="fas fa-user"></i> VALENTINA C. L.
           </v-col>

           </v-col>
           <v-col cols="5" class="color-2 text-left box-info">
             <v-col class="up-text">
             <h2>Nombre Médico</h2>
             </v-col>
           <v-col class="text-pers display-2">
                <i class="fas fa-user-md"></i> CRISTÓBAL FRAUMERI
           </v-col>
           </v-col>
           <v-col cols="2" class="color-3 box-info text-left">
            
           <v-col class="text-pers display-2 mt-3">
                <i class="fas fa-paste"></i> BOX
           </v-col>
           
           </v-col>
            <!--fin items-->
              <!--items-->
         <v-col cols="5" class="color-1 box-info text-left">
          <v-col class="up-text">
             <h2>Nombre Paciente</h2>
             </v-col>
           <v-col class="text-pers display-2">
               <i class="fas fa-user"></i> VALENTINA C. L.
           </v-col>

           </v-col>
           <v-col cols="5" class="color-2 text-left box-info">
             <v-col class="up-text">
             <h2>Nombre Médico</h2>
             </v-col>
           <v-col class="text-pers display-2">
                <i class="fas fa-user-md"></i> CRISTÓBAL FRAUMERI
           </v-col>
           </v-col>
           <v-col cols="2" class="color-3 box-info text-left">
            
           <v-col class="text-pers display-2 mt-3">
                <i class="fas fa-paste"></i> BOX
           </v-col>
           
           </v-col>
            <!--fin items-->
            <!--items-->
         <v-col cols="5" class="color-1 box-info text-left">
          <v-col class="up-text">
             <h2>Nombre Paciente</h2>
             </v-col>
           <v-col class="text-pers display-2">
               <i class="fas fa-user"></i> VALENTINA C. L.
           </v-col>

           </v-col>
           <v-col cols="5" class="color-2 text-left box-info">
             <v-col class="up-text">
             <h2>Nombre Médico</h2>
             </v-col>
           <v-col class="text-pers display-2">
                <i class="fas fa-user-md"></i> CRISTÓBAL FRAUMERI
           </v-col>
           </v-col>
           <v-col cols="2" class="color-3 box-info text-left">
            
           <v-col class="text-pers display-2 mt-3">
                <i class="fas fa-paste"></i> BOX
           </v-col>
           
           </v-col>
            <!--fin items-->
              <!--items-->
         <v-col cols="5" class="color-1 box-info text-left">
          <v-col class="up-text">
             <h2>Nombre Paciente</h2>
             </v-col>
           <v-col class="text-pers display-2">
               <i class="fas fa-user"></i> VALENTINA C. L.
           </v-col>

           </v-col>
           <v-col cols="5" class="color-2 text-left box-info">
             <v-col class="up-text">
             <h2>Nombre Médico</h2>
             </v-col>
           <v-col class="text-pers display-2">
                <i class="fas fa-user-md"></i> CRISTÓBAL FRAUMERI
           </v-col>
           </v-col>
           <v-col cols="2" class="color-3 box-info text-left">
            
           <v-col class="text-pers display-2 mt-3">
                <i class="fas fa-paste"></i> BOX
           </v-col>
           
           </v-col>
            <!--fin items-->
              <!--items-->
         <v-col cols="5" class="color-1 box-info text-left">
          <v-col class="up-text">
             <h2>Nombre Paciente</h2>
             </v-col>
           <v-col class="text-pers display-2">
               <i class="fas fa-user"></i> VALENTINA C. L.
           </v-col>

           </v-col>
           <v-col cols="5" class="color-2 text-left box-info">
             <v-col class="up-text">
             <h2>Nombre Médico</h2>
             </v-col>
           <v-col class="text-pers display-2">
                <i class="fas fa-user-md"></i> CRISTÓBAL FRAUMERI
           </v-col>
           </v-col>
           <v-col cols="2" class="color-3 box-info text-left">
            
           <v-col class="text-pers display-2 mt-3">
                <i class="fas fa-paste"></i> BOX
           </v-col>
           
           </v-col>
            <!--fin items-->
              <!--items-->
         <v-col cols="5" class="color-1 box-info text-left">
          <v-col class="up-text">
             <h2>Nombre Paciente</h2>
             </v-col>
           <v-col class="text-pers display-2">
               <i class="fas fa-user"></i> VALENTINA C. L.
           </v-col>

           </v-col>
           <v-col cols="5" class="color-2 text-left box-info">
             <v-col class="up-text">
             <h2>Nombre Médico</h2>
             </v-col>
           <v-col class="text-pers display-2">
                <i class="fas fa-user-md"></i> CRISTÓBAL FRAUMERI
           </v-col>
           </v-col>
           <v-col cols="2" class="color-3 box-info text-left">
            
           <v-col class="text-pers display-2 mt-3">
                <i class="fas fa-paste"></i> BOX
           </v-col>
           
           </v-col>
            <!--fin items-->
            
      </v-row>
      
       

       
   

   
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data() {
      return {
        nombre:null,
        medico:null,
        box:null,
      }
    },
    methods: {
      async conecta(grupo) {
        //var data = { soundurl : '/mp3/alarm1.mp3'}
        //if (this.$store.state.turnoActivo) {
          console.log("Grupo llega "+grupo)
          const oficina = "pantalla" + grupo;
          await this.$hubs.on(oficina, (message) => {
            console.log("Hub Transmite: Grupo "+ oficina);
            let asignar = JSON.stringify(message)
            let variable = JSON.parse(asignar)
            this.nombre = variable.nombreusuario;
            this.medico = variable.nombremedico;
            this.box = variable.box + " piso "+ variable.piso;
          });
        //}
      },
      async start() {
        console.log("Entro a conectar")
        try {
          await this.$hubs.start();
          console.log("connected");
        } catch (err) {
          console.log(err);
          setTimeout(() => this.start(), 1000);
        }
      },
    },
    async mounted() {
      //console.log(process.env.VUE_APP_TITLE)
      //await this.start();
      console.log("Entro a seguir")
      //this.title = process.env.VUE_APP_TITLE
      this.nombre = "Refrescando atenciones ...."
      //await this.turnos();
      this.$hubs.onclose(async () => {
        await this.start();
      })
      await this.conecta("1d55297e-125f-4179-8a65-6dd13f595a5e")

    }
  }
</script>

<style scoped>



.box-info{
height: 112px;
margin-bottom:5px ;
width: 98%;
}
.color-1 {
    background-color: #008181;
    
border-radius: 15px 20px 20px;
   
    border-right: 5px solid white;
    color: white;
}
.color-2{
   background-color: #009999;
    color: white;
    border-radius: 15px 20px 20px;
   
    border-right: 5px solid white;
    color: white;
}

.color-3{
   background-color: #98ca3c;
    color: white;
    border-radius: 15px 20px 20px;
}
.box-container{
  margin-top: 25px!important;
}
.up-text, .text-pers{
  margin-top: -20px;
}


</style>
