//import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
const signalR = require('@microsoft/signalr')


const connection = new signalR.HubConnectionBuilder()
    .withUrl('https://apitolweb.totalpack.cl/v2/turno-hub',{
        //skipNegotiation: true,
        //transport: signalR.HttpTransportType.LongPolling
        //transport: signalR.HttpTransportType.ServerSentEvents
        transport: signalR.HttpTransportType.WebSockets
    })
    //.withAutomaticReconnect([0, 1000, 5000, 10000,15000,20000,60000,null])
    .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
            if (retryContext.elapsedMilliseconds < 5000) {
                // If we've been reconnecting for less than 60 seconds so far,
                // wait between 0 and 10 seconds before the next reconnect attempt.
                return Math.random() * 500;
            } else {
                // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                return null;
            }
        }
    })
    .configureLogging(signalR.LogLevel.Information)
    .build()
connection.start().catch(function (err) {
    return console.error(err.toString())
})

//connection.start().catch(err => console.error(err.toString()));

export default connection;